import Footer from '../components/Footer'

const Home = () => {
  

  return (
    <>
      <div className="wrapper">
        <div className="content">
          <div className="logo-container">
              <div className="logo">
                  Keystone<span className="bid">Bid</span>
              </div>
              <div className="tagline">
                  Your Foundation for Proposal Success
              </div>
          </div>

          <br/><br/><br/>
          <hr/>
          <br/>
          
          <div className="ml-embedded" data-form="TUeNiy"></div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Home