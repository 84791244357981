import Home from './pages/Home'
import Scorecard from './pages/Scorecard'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'


function App() {

  const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/scorecard', element: <Scorecard /> },
  ])

  return (
    <RouterProvider router={router} />
  )
}

export default App