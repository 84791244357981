import React, { useState } from 'react'
import './Scorecard.css'

import { recommendations } from '../text/scorecardText'


const ScoreCard = () => {
  const [scores, setScores] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [expandedSections, setExpandedSections] = useState({})

  const sections = {
    'Planning and Strategy': [
      'Opportunity Qualification Process',
      'Proposal Timeline Management',
      'Team Organization',
      'Compliance Matrix Development',
      'Win Strategy Development'
    ],
    'Content Development': [
      'Technical Approach',
      'Past Performance',
      'Management Approach',
      'Pricing Strategy',
      'Quality Control'
    ],
    'Review Process': [
      'Color Team Reviews',
      'Executive Review',
      'Graphics Review',
      'Cost/Price Review',
      'Production Quality'
    ],
    'Process Management': [
      'Document Control',
      'Knowledge Management',
      'Training Program',
      'Tools and Technology',
      'Continuous Improvement'
    ]
  }

  
  

  const handleScoreChange = (section, criterion, value) => {
    setScores(prev => ({
      ...prev,
      [`${section}-${criterion}`]: parseInt(value)
    }))
  }

  const calculateSectionScore = (section) => {
    let total = 0
    sections[section].forEach(criterion => {
      total += scores[`${section}-${criterion}`] || 0
    })
    return total
  }

  const calculateTotalScore = () => {
    let total = 0
    Object.keys(sections).forEach(section => {
      total += calculateSectionScore(section)
    })
    return total
  }

  const getSectionRating = (score) => {
    if (score >= 20) return 'high'
    if (score >= 15) return 'medium'
    return 'low'
  }

  const getRatingColor = (score) => {
    if (score >= 90) return 'rating-outstanding'
    if (score >= 80) return 'rating-strong'
    if (score >= 70) return 'rating-adequate'
    if (score >= 60) return 'rating-basic'
    return 'rating-insufficient'
  }

  const getRatingText = (score) => {
    if (score >= 90) return 'Outstanding'
    if (score >= 80) return 'Strong'
    if (score >= 70) return 'Adequate'
    if (score >= 60) return 'Basic'
    return 'Insufficient'
  }

  const handleSubmit = () => {
    const totalItems = Object.values(sections).flat().length
    const scoredItems = Object.keys(scores).length
    
    if (scoredItems < totalItems) {
      alert('Please complete all sections before submitting.')
      return
    }
    
    setIsSubmitted(true)
  }

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const totalScore = calculateTotalScore()

  return (
    <div className="scorecard">
      {!isSubmitted ? (
        <div className="scorecard-container">

          <div className="scorecard-header">
            <h1 className="title">Proposal Development Scorecard</h1>
          </div>

          <div className="legend">
            Options:
            <ul>
              <li><strong>Not Implemented</strong> - No process or very basic ad-hoc processes in place</li>
              <li><strong>Basic Implementation</strong> - Some processes implemented but lacking consistency and documentation</li>
              <li><strong>Moderate Implementation</strong> - Consistent processes in place with basic documentation and moderate effectiveness</li>
              <li><strong>Strong Implementation</strong> - Well-documented processes consistently followed with proven effectiveness</li>
              <li><strong>Excellent Implementation</strong> - Fully structured processes with continuous improvement and measurable results</li>
            </ul>
          </div>

          <div className="scorecard-content">
            {Object.entries(sections).map(([section, criteria]) => (
              <div key={section} className="section">
                <h2>{section} - Score: {calculateSectionScore(section)}/25</h2>
                <div className="criteria-list">
                  {criteria.map((criterion) => (
                    <div key={criterion} className="criterion">
                      <div className="criterion-header">
                        <label>{criterion}</label>
                        <select
                          value={scores[`${section}-${criterion}`] || '0'}
                          onChange={(e) => handleScoreChange(section, criterion, e.target.value)}
                        >
                          <option value="0">Select...</option>
                          <option value="1">1 - Not Implemented</option>
                          <option value="2">2 - Basic Implementation</option>
                          <option value="3">3 - Moderate Implementation</option>
                          <option value="4">4 - Strong Implementation</option>
                          <option value="5">5 - Excellent Implementation</option>
                        </select>
                      </div>
                      <div className="progress-bar">
                        <div 
                          className="progress-fill"
                          style={{ width: `${(scores[`${section}-${criterion}`] || 0) * 20}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <button className="submit-button" onClick={handleSubmit}>
              Submit Assessment
            </button>
          </div>
        </div>
      ) : (
        <div className="results-container">
          <div className="results-header">
            <h1>Assessment Results</h1>
            <div className="total-score">
              <h2>Overall Score: {totalScore}/100</h2>
              <span className={`rating-badge ${getRatingColor(totalScore)}`}>
                {getRatingText(totalScore)}
              </span>
            </div>
          </div>

          <br/>
          
          <div className="results-content">
            {Object.keys(sections).map((section) => {
              const sectionScore = calculateSectionScore(section)
              const rating = getSectionRating(sectionScore)
              return (
                <div key={section} className="result-section">
                  <div 
                    className="section-header"
                    onClick={() => toggleSection(section)}
                  >
                    <div>
                      <h3>{section}</h3>
                      <div className="section-score">
                        <div className="progress-bar">
                          <div 
                            className="progress-fill"
                            style={{ width: `${(sectionScore/25) * 100}%` }}
                          ></div>
                        </div>
                        <span>{sectionScore}/25</span>
                      </div>
                    </div>
                    <span className="expand-icon">
                      {expandedSections[section] ? '▼' : '▶'}
                    </span>
                  </div>
                  
                  {expandedSections[section] && (
                    <div className="section-details">
                      <div className="recommendation">
                        <h4>Recommendations</h4>
                        <p dangerouslySetInnerHTML={{ __html: recommendations[section][rating] }} />
                      </div>
                      <div className="criteria-scores">
                        {sections[section].map((criterion) => (
                          <div key={criterion} className="criterion-score">
                            <span>{criterion}</span>
                            <span>{scores[`${section}-${criterion}`]}/5</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <button 
            className="submit-button"
            onClick={() => setIsSubmitted(false)}
          >
            Return to Assessment
          </button>
        </div>
      )}
    </div>
  )
}

export default ScoreCard