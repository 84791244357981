
export const recommendations = {
  'Planning and Strategy': {
    low: `
      <h4>Key Actions:</h4>
      <ol>
        <li>
          Implement a structured go/no-go decision matrix with weighted criteria including:
          <ul>
            <li>Contract value vs. bid & proposal costs</li>
            <li>Competition analysis</li>
            <li>Past performance relevance</li>
            <li>Resource availability</li>
            <li>Win probability assessment</li>
          </ul>
        </li>
        <li>
          Develop standardized capture planning templates:
          <ul>
            <li>Customer relationship mapping</li>
            <li>Competitive analysis framework</li>
            <li>Solution development checklist</li>
            <li>Pricing strategy worksheet</li>
          </ul>
        </li>
        <li>
          Create proposal timeline templates with:
          <ul>
            <li>Standard milestone definitions</li>
            <li>Resource allocation guidelines</li>
            <li>Dependencies tracking</li>
            <li>Risk mitigation plans</li>
          </ul>
        </li>
      </ol>
      <h4>Next Steps:</h4>
      <ul>
        <li>Schedule consultation with proposal experts for process development</li>
        <li>Invest in proposal management training for key personnel</li>
        <li>Begin tracking bid decisions and outcomes</li>
      </ul>`,

    medium: `
      <h4>Enhancement Priorities:</h4>
      <ol>
        <li>
          Strengthen opportunity assessment by:
          <ul>
            <li>Implementing automated tracking systems</li>
            <li>Developing customer relationship scoring</li>
            <li>Creating competition capability matrices</li>
            <li>Establishing price-to-win analysis process</li>
          </ul>
        </li>
        <li>
          Enhance competitive analysis through:
          <ul>
            <li>Regular competitor capability updates</li>
            <li>Win/loss analysis database</li>
            <li>Pricing trend analysis</li>
            <li>Solution differentiation tracking</li>
          </ul>
        </li>
        <li>
          Improve strategy documentation:
          <ul>
            <li>Create searchable strategy database</li>
            <li>Implement regular strategy reviews</li>
            <li>Develop performance metrics</li>
            <li>Schedule quarterly process audits</li>
          </ul>
        </li>
      </ol>
      <h4>Focus Areas:</h4>
      <ul>
        <li>Automate data collection and analysis</li>
        <li>Develop metrics for process effectiveness</li>
        <li>Establish regular review cycles</li>
      </ul>`,

    high: `
      <h4>Excellence Maintenance Plan:</h4>
      <ol>
        <li>
          Document and systematize best practices:
          <ul>
            <li>Create process playbooks</li>
            <li>Develop training materials</li>
            <li>Establish metrics dashboard</li>
            <li>Implement innovation tracking</li>
          </ul>
        </li>
        <li>
          Build mentorship program:
          <ul>
            <li>Create formal mentoring structure</li>
            <li>Develop training curriculum</li>
            <li>Establish certification levels</li>
            <li>Track mentorship outcomes</li>
          </ul>
        </li>
        <li>
          Drive continuous improvement:
          <ul>
            <li>Regular process audits</li>
            <li>Innovation workshops</li>
            <li>Cross-team collaboration</li>
            <li>Industry benchmarking</li>
          </ul>
        </li>
      </ol>
      <h4>Leadership Initiatives:</h4>
      <ul>
        <li>Lead industry working groups</li>
        <li>Publish best practices</li>
        <li>Develop center of excellence</li>
      </ul>`
  },

  'Content Development': {
    low: `
      <h4>Immediate Actions:</h4>
      <ol>
        <li>
          Implement basic template library:
          <ul>
            <li>Executive Summaries</li>
            <li>Past Performance</li>
            <li>Management Approaches</li>
            <li>Technical Solutions</li>
            <li>Pricing Volumes</li>
          </ul>
        </li>
        <li>
          Establish writing guidelines:
          <ul>
            <li>Style guide implementation</li>
            <li>Compliance checklists</li>
            <li>Graphics standards</li>
            <li>Review criteria</li>
          </ul>
        </li>
        <li>
          Deploy training program:
          <ul>
            <li>Basic proposal writing</li>
            <li>RFP analysis</li>
            <li>Graphics development</li>
            <li>Compliance training</li>
          </ul>
        </li>
      </ol>
      <h4>Resource Needs:</h4>
      <ul>
        <li>Template development tools</li>
        <li>Writing guide resources</li>
        <li>Training materials</li>
      </ul>`,

    medium: `
      <h4>Quality Enhancement Plan:</h4>
      <ol>
        <li>
          Strengthen content development:
          <ul>
            <li>Create content reuse library</li>
            <li>Implement SME engagement process</li>
            <li>Develop solution frameworks</li>
            <li>Establish graphics standards</li>
          </ul>
        </li>
        <li>
          Enhance review procedures:
          <ul>
            <li>Define review criteria</li>
            <li>Create feedback templates</li>
            <li>Track common issues</li>
            <li>Measure improvement</li>
          </ul>
        </li>
        <li>
          Improve past performance:
          <ul>
            <li>Create reference database</li>
            <li>Implement regular updates</li>
            <li>Develop metrics tracking</li>
            <li>Establish validation process</li>
          </ul>
        </li>
      </ol>
      <h4>Focus Areas:</h4>
      <ul>
        <li>Content management system</li>
        <li>Quality metrics dashboard</li>
        <li>Training program expansion</li>
      </ul>`,

    high: `
      <h4>Innovation Agenda:</h4>
      <ol>
        <li>
          Advanced content strategies:
          <ul>
            <li>AI integration plan</li>
            <li>Content optimization</li>
            <li>Automated compliance</li>
            <li>Innovation tracking</li>
          </ul>
        </li>
        <li>
          Visual excellence program:
          <ul>
            <li>Graphics innovation</li>
            <li>Interactive content</li>
            <li>Visual standards</li>
            <li>Measurement metrics</li>
          </ul>
        </li>
        <li>
          Knowledge sharing:
          <ul>
            <li>Best practices database</li>
            <li>Training programs</li>
            <li>Industry leadership</li>
            <li>Innovation tracking</li>
          </ul>
        </li>
      </ol>
      <h4>Leadership Focus:</h4>
      <ul>
        <li>Industry thought leadership</li>
        <li>Innovation workshops</li>
        <li>Process automation</li>
      </ul>`
  },

  'Review Process': {
    low: `
      <h4>Critical Implementation:</h4>
      <ol>
        <li>
          Establish color team process:
          <ul>
            <li>Pink Team (Initial Review)</li>
            <li>Red Team (Technical Review)</li>
            <li>Gold Team (Management Review)</li>
            <li>White Team (Compliance Check)</li>
          </ul>
        </li>
        <li>
          Define review standards:
          <ul>
            <li>Review checklists</li>
            <li>Scoring criteria</li>
            <li>Feedback templates</li>
            <li>Issue tracking</li>
          </ul>
        </li>
        <li>
          Set up basic tools:
          <ul>
            <li>Review scheduler</li>
            <li>Comment tracking</li>
            <li>Action item logging</li>
            <li>Resolution tracking</li>
          </ul>
        </li>
      </ol>
      <h4>Initial Focus:</h4>
      <ul>
        <li>Basic process implementation</li>
        <li>Team training</li>
        <li>Tool selection</li>
      </ul>`,

    medium: `
      <h4>Process Enhancement:</h4>
      <ol>
        <li>
          Improve review documentation:
          <ul>
            <li>Standardized forms</li>
            <li>Digital workflows</li>
            <li>Automated tracking</li>
            <li>Metrics dashboard</li>
          </ul>
        </li>
        <li>
          Enhance feedback systems:
          <ul>
            <li>Comment categorization</li>
            <li>Priority tracking</li>
            <li>Resolution verification</li>
            <li>Trend analysis</li>
          </ul>
        </li>
        <li>
          Strengthen executive involvement:
          <ul>
            <li>Regular briefings</li>
            <li>Decision tracking</li>
            <li>Risk assessment</li>
            <li>Strategic alignment</li>
          </ul>
        </li>
      </ol>
      <h4>Optimization Areas:</h4>
      <ul>
        <li>Workflow automation</li>
        <li>Metrics tracking</li>
        <li>Training program</li>
      </ul>`,

    high: `
      <h4>Excellence Program:</h4>
      <ol>
        <li>
          Advanced review processes:
          <ul>
            <li>AI-assisted reviews</li>
            <li>Predictive analytics</li>
            <li>Automated compliance</li>
            <li>Quality scoring</li>
          </ul>
        </li>
        <li>
          Knowledge management:
          <ul>
            <li>Lesson database</li>
            <li>Training materials</li>
            <li>Best practices</li>
            <li>Innovation tracking</li>
          </ul>
        </li>
        <li>
          Industry leadership:
          <ul>
            <li>Process publication</li>
            <li>Training programs</li>
            <li>Certification development</li>
            <li>Consulting services</li>
          </ul>
        </li>
      </ol>
      <h4>Focus Areas:</h4>
      <ul>
        <li>Process automation</li>
        <li>Industry leadership</li>
        <li>Innovation tracking</li>
      </ul>`
  },

  'Process Management': {
    low: `
      <h4>Foundation Building:</h4>
      <ol>
        <li>
          Document control basics:
          <ul>
            <li>Version control system</li>
            <li>Naming conventions</li>
            <li>Access controls</li>
            <li>Backup procedures</li>
          </ul>
        </li>
        <li>
          Basic workflows:
          <ul>
            <li>Document templates</li>
            <li>Review procedures</li>
            <li>Approval processes</li>
            <li>Storage protocols</li>
          </ul>
        </li>
        <li>
          Essential tools:
          <ul>
            <li>File sharing platform</li>
            <li>Basic tracking tools</li>
            <li>Communication system</li>
            <li>Calendar management</li>
          </ul>
        </li>
      </ol>
      <h4>Initial Investments:</h4>
      <ul>
        <li>Basic software tools</li>
        <li>Process documentation</li>
        <li>Team training</li>
      </ul>`,

    medium: `
      <h4>System Enhancement:</h4>
      <ol>
        <li>
          Knowledge management:
          <ul>
            <li>Content database</li>
            <li>Search functionality</li>
            <li>Access controls</li>
            <li>Update procedures</li>
          </ul>
        </li>
        <li>
          Training program:
          <ul>
            <li>Role-based training</li>
            <li>Certification paths</li>
            <li>Skill tracking</li>
            <li>Performance metrics</li>
          </ul>
        </li>
        <li>
          Process automation:
          <ul>
            <li>Workflow tools</li>
            <li>Status tracking</li>
            <li>Metrics dashboard</li>
            <li>Report generation</li>
          </ul>
        </li>
      </ol>
      <h4>Focus Areas:</h4>
      <ul>
        <li>System integration</li>
        <li>Automation expansion</li>
        <li>Training development</li>
      </ul>`,

    high: `
      <h4>Innovation Leadership:</h4>
      <ol>
        <li>
          Advanced systems:
          <ul>
            <li>AI integration</li>
            <li>Predictive analytics</li>
            <li>Automated workflows</li>
            <li>Performance optimization</li>
          </ul>
        </li>
        <li>
          Process innovation:
          <ul>
            <li>Continuous improvement</li>
            <li>Innovation tracking</li>
            <li>Best practices</li>
            <li>Industry leadership</li>
          </ul>
        </li>
        <li>
          Knowledge leadership:
          <ul>
            <li>Training programs</li>
            <li>Certification development</li>
            <li>Industry publication</li>
            <li>Consulting services</li>
          </ul>
        </li>
      </ol>
      <h4>Strategic Focus:</h4>
      <ul>
        <li>Industry leadership</li>
        <li>Innovation development</li>
        <li>Service expansion</li>
      </ul>`
  }
}




// const recommendations = {
  //   'Planning and Strategy': {
  //     low: "Focus on developing structured go/no-go criteria and implementing a formal win strategy process. Consider bringing in expert consultants to establish robust planning frameworks.",
  //     medium: "Strengthen documentation of opportunity assessment process and enhance competitive analysis procedures.",
  //     high: "Continue excellence while documenting best practices. Consider mentoring other teams."
  //   },
  //   'Content Development': {
  //     low: "Implement standardized templates and establish clear technical writing guidelines. Consider investing in proposal writing training for key personnel.",
  //     medium: "Enhance quality control procedures and strengthen past performance documentation process.",
  //     high: "Share successful content strategies across teams and consider developing innovative visualization techniques."
  //   },
  //   'Review Process': {
  //     low: "Establish formal color team review process and implement structured executive review checkpoints. Define clear review criteria.",
  //     medium: "Strengthen documentation of review findings and improve feedback implementation tracking.",
  //     high: "Document successful review practices and consider developing review training materials."
  //   },
  //   'Process Management': {
  //     low: "Implement basic document control systems and establish version control procedures. Consider investing in proposal management software.",
  //     medium: "Enhance knowledge management systems and strengthen training programs.",
  //     high: "Document and share process innovations. Consider developing process improvement workshops."
  //   }
  // }

  // const recommendations = {
  //   'Planning and Strategy': {
  //     low: `Key Actions:
  //       1. Implement a structured go/no-go decision matrix with weighted criteria including:
  //         - Contract value vs. bid & proposal costs
  //         - Competition analysis
  //         - Past performance relevance
  //         - Resource availability
  //         - Win probability assessment

  //       2. Develop standardized capture planning templates:
  //         - Customer relationship mapping
  //         - Competitive analysis framework
  //         - Solution development checklist
  //         - Pricing strategy worksheet

  //       3. Create proposal timeline templates with:
  //         - Standard milestone definitions
  //         - Resource allocation guidelines
  //         - Dependencies tracking
  //         - Risk mitigation plans

  //       Next Steps:
  //       • Schedule consultation with proposal experts for process development
  //       • Invest in proposal management training for key personnel
  //       • Begin tracking bid decisions and outcomes`,

  //             medium: `Enhancement Priorities:
  //       1. Strengthen opportunity assessment by:
  //         - Implementing automated tracking systems
  //         - Developing customer relationship scoring
  //         - Creating competition capability matrices
  //         - Establishing price-to-win analysis process

  //       2. Enhance competitive analysis through:
  //         - Regular competitor capability updates
  //         - Win/loss analysis database
  //         - Pricing trend analysis
  //         - Solution differentiation tracking

  //       3. Improve strategy documentation:
  //         - Create searchable strategy database
  //         - Implement regular strategy reviews
  //         - Develop performance metrics
  //         - Schedule quarterly process audits

  //       Focus Areas:
  //       • Automate data collection and analysis
  //       • Develop metrics for process effectiveness
  //       • Establish regular review cycles`,

  //             high: `Excellence Maintenance Plan:
  //       1. Document and systematize best practices:
  //         - Create process playbooks
  //         - Develop training materials
  //         - Establish metrics dashboard
  //         - Implement innovation tracking

  //       2. Build mentorship program:
  //         - Create formal mentoring structure
  //         - Develop training curriculum
  //         - Establish certification levels
  //         - Track mentorship outcomes

  //       3. Drive continuous improvement:
  //         - Regular process audits
  //         - Innovation workshops
  //         - Cross-team collaboration
  //         - Industry benchmarking

  //       Leadership Initiatives:
  //       • Lead industry working groups
  //       • Publish best practices
  //       • Develop center of excellence`
  //           },

  //           'Content Development': {
  //             low: `Immediate Actions:
  //       1. Implement basic template library:
  //         - Executive Summaries
  //         - Past Performance
  //         - Management Approaches
  //         - Technical Solutions
  //         - Pricing Volumes

  //       2. Establish writing guidelines:
  //         - Style guide implementation
  //         - Compliance checklists
  //         - Graphics standards
  //         - Review criteria

  //       3. Deploy training program:
  //         - Basic proposal writing
  //         - RFP analysis
  //         - Graphics development
  //         - Compliance training

  //       Resource Needs:
  //       • Template development tools
  //       • Writing guide resources
  //       • Training materials`,

  //             medium: `Quality Enhancement Plan:
  //       1. Strengthen content development:
  //         - Create content reuse library
  //         - Implement SME engagement process
  //         - Develop solution frameworks
  //         - Establish graphics standards

  //       2. Enhance review procedures:
  //         - Define review criteria
  //         - Create feedback templates
  //         - Track common issues
  //         - Measure improvement

  //       3. Improve past performance:
  //         - Create reference database
  //         - Implement regular updates
  //         - Develop metrics tracking
  //         - Establish validation process

  //       Focus Areas:
  //       • Content management system
  //       • Quality metrics dashboard
  //       • Training program expansion`,

  //             high: `Innovation Agenda:
  //       1. Advanced content strategies:
  //         - AI integration plan
  //         - Content optimization
  //         - Automated compliance
  //         - Innovation tracking

  //       2. Visual excellence program:
  //         - Graphics innovation
  //         - Interactive content
  //         - Visual standards
  //         - Measurement metrics

  //       3. Knowledge sharing:
  //         - Best practices database
  //         - Training programs
  //         - Industry leadership
  //         - Innovation tracking

  //       Leadership Focus:
  //       • Industry thought leadership
  //       • Innovation workshops
  //       • Process automation`
  //           },

  //           'Review Process': {
  //             low: `Critical Implementation:
  //       1. Establish color team process:
  //         - Pink Team (Initial Review)
  //         - Red Team (Technical Review)
  //         - Gold Team (Management Review)
  //         - White Team (Compliance Check)

  //       2. Define review standards:
  //         - Review checklists
  //         - Scoring criteria
  //         - Feedback templates
  //         - Issue tracking

  //       3. Set up basic tools:
  //         - Review scheduler
  //         - Comment tracking
  //         - Action item logging
  //         - Resolution tracking

  //       Initial Focus:
  //       • Basic process implementation
  //       • Team training
  //       • Tool selection`,

  //             medium: `Process Enhancement:
  //       1. Improve review documentation:
  //         - Standardized forms
  //         - Digital workflows
  //         - Automated tracking
  //         - Metrics dashboard

  //       2. Enhance feedback systems:
  //         - Comment categorization
  //         - Priority tracking
  //         - Resolution verification
  //         - Trend analysis

  //       3. Strengthen executive involvement:
  //         - Regular briefings
  //         - Decision tracking
  //         - Risk assessment
  //         - Strategic alignment

  //       Optimization Areas:
  //       • Workflow automation
  //       • Metrics tracking
  //       • Training program`,

  //             high: `Excellence Program:
  //       1. Advanced review processes:
  //         - AI-assisted reviews
  //         - Predictive analytics
  //         - Automated compliance
  //         - Quality scoring

  //       2. Knowledge management:
  //         - Lesson database
  //         - Training materials
  //         - Best practices
  //         - Innovation tracking

  //       3. Industry leadership:
  //         - Process publication
  //         - Training programs
  //         - Certification development
  //         - Consulting services

  //       Focus Areas:
  //       • Process automation
  //       • Industry leadership
  //       • Innovation tracking`
  //           },

  //           'Process Management': {
  //             low: `Foundation Building:
  //       1. Document control basics:
  //         - Version control system
  //         - Naming conventions
  //         - Access controls
  //         - Backup procedures

  //       2. Basic workflows:
  //         - Document templates
  //         - Review procedures
  //         - Approval processes
  //         - Storage protocols

  //       3. Essential tools:
  //         - File sharing platform
  //         - Basic tracking tools
  //         - Communication system
  //         - Calendar management

  //       Initial Investments:
  //       • Basic software tools
  //       • Process documentation
  //       • Team training`,

  //             medium: `System Enhancement:
  //       1. Knowledge management:
  //         - Content database
  //         - Search functionality
  //         - Access controls
  //         - Update procedures

  //       2. Training program:
  //         - Role-based training
  //         - Certification paths
  //         - Skill tracking
  //         - Performance metrics

  //       3. Process automation:
  //         - Workflow tools
  //         - Status tracking
  //         - Metrics dashboard
  //         - Report generation

  //       Focus Areas:
  //       • System integration
  //       • Automation expansion
  //       • Training development`,

  //             high: `Innovation Leadership:
  //       1. Advanced systems:
  //         - AI integration
  //         - Predictive analytics
  //         - Automated workflows
  //         - Performance optimization

  //       2. Process innovation:
  //         - Continuous improvement
  //         - Innovation tracking
  //         - Best practices
  //         - Industry leadership

  //       3. Knowledge leadership:
  //         - Training programs
  //         - Certification development
  //         - Industry publication
  //         - Consulting services

  //       Strategic Focus:
  //       • Industry leadership
  //       • Innovation development
  //       • Service expansion`
  //           }
  //         };