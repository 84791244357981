import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
     
    </div>
  )
}

export default Footer